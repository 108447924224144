import React, {useState, useEffect} from "react";

export default function GiletPrimoKnopfenBack({
  lightContrast,
  darkContrast,
  stitching,
  patchColor,
  azomo,
  printStyle,
  getDataFromChild,
  preview,
}) {
  function adjust(color, amount) {
    return (
      "#" +
      color
        .replace(/^#/, "")
        .replace(/../g, (color) =>
          (
            "0" + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
          ).substr(-2)
        )
    );
  }

  const [darkenColor, setDarkenColor] = useState(darkContrast);

  const [lightContrastActive, setLightContrastActive] = useState(false);
  const [darkContrastActive, setDarkContrastActive] = useState(false);
  const [stitchingActive, setStitchingActive] = useState(false);
  const [patchActive, setPatchActive] = useState(false);
  const [azomoActive, setAzomoActive] = useState(false);

  const handleClick = (tabId) => {
    if (!preview) {
      getDataFromChild(tabId);
    }
  };

  useEffect(() => {
    setDarkenColor(adjust(lightContrast, -150));
  }, lightContrast);

  return (
    <svg
      className={printStyle}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 500 500"
      enableBackground="new 0 0 500 500"
    >
      <g>
        <path
          fill={lightContrast}
          onClick={() => handleClick("base")}
          onMouseEnter={() => setLightContrastActive(true)}
          onMouseLeave={() => setLightContrastActive(false)}
          style={
            lightContrastActive && !preview
              ? {fill: adjust(lightContrast, 55)}
              : {fill: lightContrast}
          }
          d="M403.398,216.4l-0.036-0.239l-0.213-0.107c-0.071-0.035-7.285-4.043-12.932-39.463
   c-4.509-28.303,3.313-62.621,7.516-81.054c1.16-5.088,1.996-8.765,2.174-10.624c0.779-8.227-0.876-9.583-1.254-9.793
   c-0.27-0.115-27.335-11.945-48.069-18.789c-20.652-6.822-59.765-23.205-61.428-23.898l-0.303,0.058l-0.027-0.071
   c-0.141,0.048-14.564,5.127-33.002,6.235c-24.352,0.889-41.679-5.844-42.599-6.213l-0.129-0.12l-0.267,0.111
   c-0.405,0.173-40.682,17.051-61.423,23.898c-20.728,6.844-47.797,18.674-48.105,18.812c-0.337,0.187-1.992,1.543-1.215,9.77
   c0.178,1.859,1.015,5.536,2.176,10.624c4.202,18.434,12.024,52.752,7.511,81.054c-5.644,35.42-12.857,39.428-12.923,39.459
   l-0.223,0.107l-0.031,0.243c-0.009,0.075-0.956,7.819-0.56,13.819c0.983,15.048,1.543,30.395,2.08,45.236
   c0.387,10.57,0.783,21.496,1.33,32.228c1.383,27.03,2.984,51.574,4.887,75.033c1.054,12.944,2.215,25.703,3.451,37.924
   c0.699,6.866,1.344,12.906,1.97,18.455c0.077,0.663,0.117,1.602,0.162,2.682c0.155,3.775,0.368,8.948,2.413,10.566
   c0.766,0.604,1.74,0.823,2.544,1.005c0.338,0.057,34.537,5.887,84.465,8.782c15.885,0.921,34.523,1.602,54.945,1.602
   c38.801,0,84.011-2.455,128.836-10.384c0.703-0.125,1.736-0.365,2.544-1.005c2.047-1.618,2.261-6.791,2.415-10.566
   c0.044-1.081,0.085-2.019,0.156-2.682c0.632-5.549,1.277-11.589,1.971-18.455c1.241-12.221,2.402-24.98,3.451-37.924
   c1.908-23.459,3.504-48.003,4.887-75.033c0.552-10.731,0.947-21.657,1.33-32.228c0.542-14.841,1.098-30.188,2.085-45.236
   C404.354,224.219,403.408,216.476,403.398,216.4z"
        />
        <path
          fill={darkContrast}
          onClick={() => handleClick("contrast")}
          onMouseEnter={() => setDarkContrastActive(true)}
          onMouseLeave={() => setDarkContrastActive(false)}
          style={
            darkContrastActive && !preview
              ? {fill: adjust(darkContrast, 55)}
              : {fill: darkContrast}
          }
          d="M403.146,228.656c-2.198-2.317-9.121-10.259-14.712-23.538c-3.099-7.369-4.233-15.57-5.211-24.032
   c-1.451-12.541-1.953-22.73-1.588-32.073c0.427-10.816,2.543-21.79,4.406-31.477l0.054-0.285c0.485-2.513,0.987-5.109,1.459-7.667
   c1.454-7.928,2.903-15.699,4.3-23.08c0.066-0.302,1.601-7.137,0.101-13.279c3.607,1.526,5.933,2.535,6.298,2.696
   c0.156,0.146,1.463,1.623,0.774,8.912c-0.174,1.801-1.007,5.456-2.157,10.508c-4.216,18.495-12.065,52.921-7.525,81.396
   c5.274,33.087,11.87,39.095,13.199,39.989C402.679,217.89,403.296,223.6,403.146,228.656"
        />
        <path
          fill={darkContrast}
          onClick={() => handleClick("contrast")}
          onMouseEnter={() => setDarkContrastActive(true)}
          onMouseLeave={() => setDarkContrastActive(false)}
          style={
            darkContrastActive && !preview
              ? {fill: adjust(darkContrast, 55)}
              : {fill: darkContrast}
          }
          d="M294.929,35.793c-1.13,0.969-3.549,2.441-4.554,3.025c-0.133,0.093-13.928,9.432-44.684,7.884l-0.023,0.44
   l0.013-0.444c-23.565-0.6-33.95-7.805-34.056-7.88c-0.036-0.028-2.829-1.953-4.429-3.078c3.184-1.312,5.292-2.197,5.83-2.418
   c1.735,0.675,16.899,6.306,38.388,6.306c1.454,0,2.943-0.028,4.456-0.085c17.544-1.05,31.473-5.671,33.095-6.227
   C289.596,33.579,291.74,34.477,294.929,35.793"
        />
        <path
          fill={darkContrast}
          onClick={() => handleClick("contrast")}
          onMouseEnter={() => setDarkContrastActive(true)}
          onMouseLeave={() => setDarkContrastActive(false)}
          style={
            darkContrastActive && !preview
              ? {fill: adjust(darkContrast, 55)}
              : {fill: darkContrast}
          }
          d="M118.778,181.086c-0.979,8.462-2.113,16.663-5.212,24.032c-5.586,13.279-12.514,21.221-14.711,23.538
   c-0.152-5.052,0.471-10.767,0.604-11.932c1.325-0.894,7.924-6.902,13.199-39.989c4.541-28.475-3.309-62.9-7.524-81.396
   c-1.153-5.052-1.988-8.708-2.157-10.508c-0.689-7.289,0.614-8.766,0.774-8.912c0.359-0.161,2.685-1.169,6.292-2.696
   c-1.498,6.142,0.035,12.977,0.103,13.262c1.4,7.399,2.85,15.17,4.304,23.098c0.471,2.559,0.969,5.154,1.454,7.667l0.054,0.285
   c1.868,9.687,3.98,20.661,4.407,31.476C120.734,158.356,120.232,168.545,118.778,181.086"
        />
        <path
          fill={darkContrast}
          onClick={() => handleClick("contrast")}
          onMouseEnter={() => setDarkContrastActive(true)}
          onMouseLeave={() => setDarkContrastActive(false)}
          style={
            darkContrastActive && !preview
              ? {fill: adjust(darkContrast, 55)}
              : {fill: darkContrast}
          }
          d="M389.173,442.297c-0.147,3.379-0.423,8.058-2.054,9.352c-0.654,0.516-1.539,0.721-2.148,0.827
   c-68.322,12.083-137.536,11.439-183.565,8.774c-49.879-2.891-84.037-8.716-84.352-8.77c-0.885-0.199-1.607-0.387-2.167-0.831
   c-1.494-1.183-1.854-5.2-2.015-8.463c4.692,0.699,39.74,5.754,88.495,8.25c15.742,0.805,34.001,1.392,53.85,1.392
   C295.925,452.828,343.292,450.363,389.173,442.297"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.4321"
          strokeMiterlimit="10"
          strokeDasharray="1.2829,1.2818"
          d="
   M245.668,44.344c31.306,1.582,44.964-7.962,44.964-7.962s0.78-0.451,1.742-1.055"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.4321"
          strokeMiterlimit="10"
          strokeDasharray="1.2829,1.2818"
          d="
   M99.976,225.644c3.161-3.938,7.869-10.737,11.916-20.353c3.181-7.562,4.309-16.065,5.245-24.153
   c1.233-10.661,2.012-21.407,1.589-32.143c-0.421-10.651-2.402-21.103-4.415-31.545c-0.509-2.648-1.021-5.297-1.509-7.948
   c-1.414-7.703-2.847-15.404-4.303-23.099c0,0-1.361-5.912-0.403-11.635"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.4321"
          strokeMiterlimit="10"
          strokeDasharray="1.2829,1.2818"
          d="
   M245.668,44.344c-24.009-0.614-34.297-7.962-34.297-7.962s-0.543-0.374-1.29-0.891"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.4321"
          strokeMiterlimit="10"
          strokeDasharray="1.2829,1.2818"
          d="
   M402.027,225.644c-3.161-3.938-7.871-10.737-11.916-20.353c-3.181-7.562-4.31-16.065-5.245-24.153
   c-1.233-10.661-2.013-21.407-1.589-32.143c0.421-10.651,2.402-21.103,4.414-31.545c0.51-2.648,1.022-5.297,1.509-7.948
   c1.415-7.703,2.848-15.404,4.304-23.099c0,0,1.36-5.912,0.402-11.635"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.4321"
          strokeMiterlimit="10"
          strokeDasharray="1.2829,1.2818"
          d="
   M112.402,445.55c0,0,144.88,22.507,277.237-0.901"
        />
        <path
          fill="#010101"
          d="M403.404,216.404l-0.036-0.239l-0.213-0.107c-0.071-0.035-7.285-4.043-12.932-39.463
   c-4.509-28.303,3.313-62.621,7.516-81.054c1.16-5.088,1.996-8.765,2.174-10.624c0.779-8.227-0.876-9.583-1.254-9.793
   c-0.27-0.115-27.335-11.945-48.069-18.789c-20.652-6.822-59.765-23.205-61.428-23.898l-0.303,0.058l-0.027-0.071
   c-0.141,0.048-14.564,5.127-33.002,6.235c-24.352,0.889-41.679-5.844-42.599-6.213l-0.129-0.12l-0.267,0.111
   c-0.405,0.173-40.682,17.051-61.423,23.898c-20.728,6.844-47.797,18.674-48.105,18.812c-0.337,0.187-1.992,1.543-1.215,9.77
   c0.178,1.859,1.015,5.536,2.176,10.624c4.202,18.434,12.024,52.752,7.511,81.054c-5.644,35.42-12.857,39.428-12.923,39.459
   l-0.223,0.107l-0.031,0.243c-0.009,0.075-0.956,7.819-0.56,13.819c0.983,15.048,1.543,30.395,2.08,45.236
   c0.387,10.57,0.783,21.496,1.33,32.228c1.383,27.03,2.984,51.574,4.887,75.033c1.054,12.944,2.215,25.703,3.451,37.924
   c0.699,6.866,1.344,12.906,1.97,18.455c0.077,0.663,0.117,1.602,0.162,2.682c0.155,3.775,0.368,8.948,2.413,10.566
   c0.766,0.604,1.74,0.823,2.544,1.005c0.338,0.057,34.537,5.887,84.465,8.782c15.885,0.921,34.523,1.602,54.945,1.602
   c38.801,0,84.011-2.455,128.836-10.384c0.703-0.125,1.736-0.365,2.544-1.005c2.047-1.618,2.261-6.791,2.415-10.566
   c0.044-1.081,0.085-2.019,0.156-2.682c0.632-5.549,1.277-11.589,1.971-18.455c1.241-12.221,2.402-24.98,3.451-37.924
   c1.908-23.459,3.504-48.003,4.887-75.033c0.552-10.731,0.947-21.657,1.33-32.228c0.542-14.841,1.098-30.188,2.085-45.236
   C404.36,224.223,403.413,216.479,403.404,216.404 M288.992,32.859v0.004l-0.004-0.004H288.992z M213.026,33.321
   c1.734,0.675,16.898,6.306,38.387,6.306c1.454,0,2.944-0.027,4.457-0.085c17.543-1.049,31.472-5.671,33.094-6.227
   c0.632,0.263,2.776,1.161,5.964,2.477c-1.129,0.97-3.548,2.442-4.554,3.025c-0.132,0.094-13.927,9.433-44.684,7.885l-0.022,0.439
   l0.013-0.443c-23.566-0.6-33.95-7.806-34.057-7.881c-0.035-0.027-2.828-1.952-4.429-3.077
   C210.38,34.427,212.488,33.544,213.026,33.321 M99.46,216.725c1.325-0.894,7.924-6.902,13.199-39.989
   c4.541-28.475-3.309-62.9-7.524-81.396c-1.153-5.052-1.988-8.708-2.157-10.508c-0.689-7.289,0.614-8.766,0.774-8.912
   c0.359-0.161,2.685-1.169,6.292-2.696c-1.498,6.142,0.035,12.977,0.103,13.262c1.4,7.399,2.85,15.17,4.304,23.098
   c0.471,2.559,0.969,5.154,1.454,7.667l0.054,0.285c1.868,9.687,3.98,20.661,4.407,31.477c0.369,9.343-0.133,19.532-1.587,32.073
   c-0.979,8.462-2.113,16.663-5.212,24.032c-5.586,13.279-12.514,21.221-14.711,23.538C98.704,223.605,99.327,217.89,99.46,216.725
    M387.118,451.649c-0.654,0.516-1.539,0.721-2.148,0.827c-68.322,12.083-137.536,11.439-183.565,8.774
   c-49.879-2.891-84.037-8.716-84.352-8.77c-0.885-0.199-1.606-0.387-2.166-0.831c-1.495-1.183-1.854-5.2-2.016-8.464
   c4.692,0.699,39.741,5.754,88.495,8.25c15.743,0.805,34.002,1.392,53.85,1.392c40.709,0,88.076-2.465,133.957-10.531
   C389.026,445.676,388.75,450.355,387.118,451.649 M403.075,230.164c-0.987,15.062-1.543,30.419-2.086,45.264
   c-0.383,10.566-0.779,21.487-1.329,32.21c-1.379,27.021-2.98,51.555-4.883,75.009c-1.055,12.941-2.211,25.692-3.452,37.908
   c-0.694,6.866-1.339,12.897-1.966,18.442c-0.071,0.618-0.111,1.446-0.152,2.388c-68.218,12.02-139.75,11.62-187.788,9.166
   c-49.395-2.531-84.701-7.689-88.588-8.273c-0.008-0.181-0.018-0.359-0.022-0.533c-0.05-1.102-0.09-2.049-0.165-2.748
   c-0.632-5.545-1.273-11.576-1.97-18.442c-1.236-12.216-2.398-24.967-3.446-37.908c-1.908-23.454-3.505-47.988-4.888-75.009
   c-0.547-10.723-0.947-21.644-1.33-32.21c-0.537-14.845-1.097-30.201-2.085-45.264c-0.004-0.094-0.008-0.192-0.018-0.285
   c1.175-1.164,9.179-9.446,15.476-24.415c3.148-7.476,4.291-15.747,5.279-24.277c1.458-12.585,1.961-22.823,1.591-32.211
   c-0.427-10.881-2.547-21.892-4.42-31.61l-0.054-0.284c-0.484-2.509-0.987-5.107-1.454-7.663
   c-1.458-7.929-2.903-15.699-4.309-23.117c-0.019-0.075-1.669-7.43,0.062-13.515c9.522-4.006,26.532-10.961,40.611-15.609
   c15.543-5.133,42.017-15.881,54.527-21.035c1.453,1.027,4.864,3.38,4.895,3.398c0.423,0.302,10.669,7.435,34.537,8.045
   c2.371,0.12,4.639,0.178,6.809,0.178c26.34,0,38.299-8.13,38.396-8.201c0.161-0.094,3.723-2.157,4.985-3.394
   c12.529,5.168,38.797,15.832,54.474,21.009c14.075,4.648,31.089,11.603,40.611,15.609c1.73,6.084,0.08,13.439,0.058,13.533
   c-1.4,7.399-2.85,15.169-4.304,23.098c-0.471,2.556-0.969,5.154-1.454,7.663l-0.054,0.284c-1.872,9.717-3.994,20.729-4.421,31.61
   c-0.373,9.389,0.134,19.626,1.588,32.211c0.987,8.53,2.135,16.801,5.278,24.277c6.298,14.969,14.307,23.251,15.477,24.415
   C403.087,229.972,403.083,230.07,403.075,230.164 M403.146,228.656c-2.198-2.317-9.121-10.259-14.712-23.538
   c-3.099-7.369-4.233-15.57-5.211-24.032c-1.451-12.541-1.953-22.73-1.588-32.073c0.427-10.816,2.543-21.79,4.406-31.477
   l0.054-0.285c0.485-2.513,0.987-5.109,1.459-7.667c1.454-7.928,2.903-15.699,4.3-23.08c0.066-0.302,1.601-7.137,0.101-13.279
   c3.607,1.526,5.933,2.535,6.298,2.696c0.156,0.146,1.463,1.623,0.774,8.912c-0.174,1.801-1.007,5.456-2.157,10.508
   c-4.216,18.495-12.065,52.921-7.525,81.396c5.274,33.087,11.87,39.095,13.199,39.989C402.679,217.89,403.296,223.6,403.146,228.656
   "
        />
      </g>
    </svg>
  );
}
